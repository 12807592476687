<template>
  <v-icon
    v-if="arrowIcon"
    v-bind="$attrs"
    x-small>
    {{ arrowIcon }}
  </v-icon>
</template>
<script>
export default {
  name: 'AppSortArrow',
  props: {
    arrowIcon: {
      type: String,
      default: null,
    },
  },
};
</script>
